import React from 'react';
import {CLICK} from 'wix-ui-core/dist/src/components/dropdown/constants';
import {Dropdown} from 'wix-ui-core/dropdown';
import {IProductOptionSelectionItem, IProductOptionsItem} from '../../../types/productDef';
import {getDropdownOptionsFromSelections} from './OptionsDropdownUtils';
import {OptionsArrow} from '../../../icons/dist/components/OptionsArrow';
import * as _ from 'lodash';

import st from './OptionsDropdown.st.css';
import s from './OptionsDropdown.scss';
import {Option} from 'wix-ui-core/dropdown-option';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';

export interface OptionsDropdownProps extends IProvidedTranslationProps, ProvidedGlobalProps {
  dropdown: IProductOptionsItem;
  onSelect(selected: IProductOptionSelectionItem[]): void;
  selected?: IProductOptionSelectionItem;
}

@withGlobalProps
@withTranslations('globals.texts')
export class OptionsDropdown extends React.Component<OptionsDropdownProps> {
  private readonly dropdownRef: React.RefObject<HTMLDivElement> = React.createRef();

  private readonly onSelect = (nextSelected: Option) => {
    const {
      onSelect,
      selected: currentSelected,
      globals: {experiments: isProductOptionsDropdownFocusEnabled},
    } = this.props;
    const selected = (currentSelected && currentSelected.id) === nextSelected.id ? null : nextSelected;
    const selection = selected
      ? (_.pick(selected, ['id', 'description', 'value', 'linkedMediaItems']) as IProductOptionSelectionItem)
      : null;

    onSelect([selection]);
    isProductOptionsDropdownFocusEnabled && this.dropdownRef.current.focus();
  };

  private renderDropdownButton(): JSX.Element {
    const {t, selected} = this.props;
    return (
      <div data-hook="options-dropdown-button" className={s.dropDown} tabIndex={0} ref={this.dropdownRef}>
        <span>{selected ? selected.description : t('SELECT_PLACE_HOLDER')}</span>
        <OptionsArrow />
      </div>
    );
  }

  public render() {
    const {
      selected,
      dropdown: {title, selections},
    } = this.props;

    const options = getDropdownOptionsFromSelections(selections);

    return (
      <div>
        <div data-hook="options-dropdown-title" className={s.title}>
          {title}
        </div>

        <div {...(st as any)('root')}>
          <Dropdown
            {...(st as any)('dropdown')}
            options={options}
            onSelect={this.onSelect}
            openTrigger={CLICK}
            allowReselect={true}
            initialSelectedIds={selected ? [selected.id] : []}>
            {this.renderDropdownButton()}
          </Dropdown>
        </div>
      </div>
    );
  }
}
